module.exports = [{
      plugin: require('/Users/max/Documents/Developer/React/dh_gatsby_new/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/max/Documents/Developer/React/dh_gatsby_new/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-59495098-3"},
    },{
      plugin: require('/Users/max/Documents/Developer/React/dh_gatsby_new/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
